import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Box, Slider, Typography } from '@mui/material';
import { Field } from 'redux-form';

import i18n from '../../../../../i18n';
import { useGetDynamicPricesDataQuery } from '../../../services';
import { getUserDevicesUser_IdSelector, getUserDevicesUserSelector } from '../../../../../redux-store/selectors/userDevices';
import { getSignInUserSelector } from '../../../../../redux-store/selectors/signIn';
import TariffOptimizedChart from './TariffOptimizedChart';
import TextField from './TextField';
import { additionalFields } from '../../../utils';
import { Tooltip } from '../../../../../components';

const TariffOptimizedMode = (props) => {
  const { formData, change } = props;
  const { tariffPriceLimit } = formData || {};
  const userId = useSelector(getUserDevicesUser_IdSelector);
  const { data } = useGetDynamicPricesDataQuery({ userId });
  const maxPrice = Math.max(...(data?.prices || []).map((price) => price[1])) + 0.03;

  const selectedUser = useSelector(getUserDevicesUserSelector);
  const signInUser = useSelector(getSignInUserSelector);
  const userCountry = (signInUser?.role?.type === 'end_user' ? signInUser.country : selectedUser.country).toLowerCase();
  const getUnitByCountry = (country) => {
    switch (country) {
      case 'switzerland':
      case 'liechtenstein':
        return 'Rp./kWh';
      default:
        return 'ct/kWh';
    }
  };

  const onChangeSlider = (event, value) => {
    if (value[0] !== tariffPriceLimit) {
      change('tariffPriceLimit', value[0]);
    }
  };

  return (
    <>
      <Box sx={{ marginTop: '-12px', marginBottom: '24px' }}>
        <Typography variant="h4" sx={{ color: 'text.primary', lineHeight: '24px' }}>
          {i18n.t('priceSignalAt')}
          {' '}
          {tariffPriceLimit}
          {' '}
          {getUnitByCountry(userCountry)}
        </Typography>
      </Box>
      <Box sx={{ marginBottom: '24px' }}>
        <Typography
          variant="body2"
          sx={{
            color: 'text.primary',
            opacity: 0.5,
            fontSize: '10px',
            lineHeight: '12px',
            fontWeight: 500,
            marginLeft: '36px'
          }}
        >
          {getUnitByCountry(userCountry)}
        </Typography>
        <TariffOptimizedChart chartData={data?.prices || []} tariffPriceLimit={tariffPriceLimit} />
      </Box>
      <Box>
        <Typography variant="body1" sx={{ color: 'text.primary', lineHeight: '20px', span: { fontSize: '16px' } }}>
          {i18n.t('tariffPriceLimit.label')}
          <span>
            <Tooltip title={i18n.t('tariffPriceLimit.tooltip')} placement="left" />
          </span>
        </Typography>
        <Slider
          sx={{
            '& .MuiSlider-thumb': {
              color: '#005A9B'
            },
            '& .MuiSlider-rail': {
              background: `linear-gradient(to right, #005A9B, #005A9B ${(tariffPriceLimit * 100) / maxPrice}%, rgba(22, 27, 43, 0.10) ${(tariffPriceLimit * 100) / maxPrice}%, rgba(22, 27, 43, 0.10) 100%)`,
              opacity: 1
            },
            '& .MuiSlider-valueLabel': {
              backgroundColor: 'unset',
              top: 0,
              color: 'text.primary',
              opacity: 0.5
            },
            '& .MuiSlider-markLabel': {
              color: 'text.primary',
              opacity: 0.5
            },
            '& .MuiSlider-mark': {
              backgroundColor: 'unset'
            }
          }}
          track="inverted"
          aria-labelledby="track-false-range-slider"
          defaultValue={[0.2]}
          value={[tariffPriceLimit]}
          min={0}
          step={0.01}
          max={maxPrice}
          disableSwap
          onChange={onChangeSlider}
        />
      </Box>
      <Box className="field-wrapper" sx={{ marginBottom: '24px' }}>
        <TextField {...additionalFields.tariffPriceLimitSocMax} name="tariffPriceLimitSocMax" />
      </Box>
      <Box className="input-field devices-checkbox-parent">
        <Field {...additionalFields.tariffPriceLimitForecast} name="tariffPriceLimitForecast" />
      </Box>
    </>
  );
};

TariffOptimizedMode.propTypes = {
  formData: PropTypes.instanceOf(Object),
  change: PropTypes.func.isRequired
};

export default TariffOptimizedMode;
